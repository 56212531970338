import React from 'react';
import {
  useTotalValueHistoryQuery,
  useTotalValueUpdatedSubscription,
  TotalValueUpdatedSubscription,
} from '../graphql/graphql';

export const useCombinedTotalValueHistory = () => {
  const [totalValueHistory] = useTotalValueHistoryQuery();
  const [totalValueSubscription] = useTotalValueUpdatedSubscription<
    TotalValueUpdatedSubscription[]
  >({}, (messages = [], response) => [response, ...messages]);

  const combinedHistory = React.useMemo(
    () => [
      ...(totalValueSubscription.data?.map((s) => s.totalValueUpdated) ?? []),
      ...(totalValueHistory.data?.totalValueHistory ?? []),
    ],
    [totalValueSubscription.data, totalValueHistory.data],
  );

  return {
    loading: totalValueHistory.fetching,
    combinedTotalValueHistory: combinedHistory,
  };
};
