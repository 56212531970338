import { ChakraProvider, Flex } from '@chakra-ui/react';
import { Provider } from 'urql';
import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { urqlClient } from './graphql/urqlClient';

import './globals.css';

export const App = (): JSX.Element => {
  return (
    <Provider value={urqlClient}>
      <ChakraProvider>
        <Flex direction="column">
          <Header />
          <Content />
          <Footer />
        </Flex>
      </ChakraProvider>
    </Provider>
  );
};
