import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { BalanceHistory } from '../../graphql/graphql';
import { WidgetWrapper } from '../WidgetWrapper';

type ExchangeBalanceWidgetProps = {
  exchange: string;
  balances: BalanceHistory[];
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const ExchangeBalanceWidget = ({
  exchange,
  balances,
}: ExchangeBalanceWidgetProps): JSX.Element => {
  const data = React.useMemo(
    () =>
      balances
        .map((b) => ({
          coin: b.coin,
          quantityInBitcoin: b.quantityInBitcoin,
          quantity: b.quantity,
        }))
        .sort((a, b) => a.coin.localeCompare(b.coin)),
    [balances],
  );

  return (
    <WidgetWrapper heading={`${exchange} balance distribution`}>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie data={data} dataKey="quantityInBitcoin" nameKey="coin">
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>

          <Legend />
          <Tooltip
            formatter={(_: number, name: string, props: any) =>
              props.payload.payload.quantity.toFixed(4)
            }
          />
        </PieChart>
      </ResponsiveContainer>
    </WidgetWrapper>
  );
};
