import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
};

export type BalanceHistory = {
  __typename?: 'BalanceHistory';
  coin: Scalars['String'];
  exchange: Scalars['String'];
  quantity: Scalars['Decimal'];
  quantityInBitcoin: Scalars['Decimal'];
  timestamp: Scalars['DateTime'];
};

export type NormalisedValueSnapshot = {
  __typename?: 'NormalisedValueSnapshot';
  timestamp: Scalars['DateTime'];
  totalValueInBitcoin: Scalars['Decimal'];
};

export type Query = {
  __typename?: 'Query';
  latestBalances: Array<BalanceHistory>;
  totalGain?: Maybe<TotalGainModel>;
  totalValueHistory: Array<NormalisedValueSnapshot>;
  tradeHistory: Array<TradeHistory>;
};

export type Subscription = {
  __typename?: 'Subscription';
  balancesUpdated: Array<BalanceHistory>;
  totalGainUpdated?: Maybe<TotalGainModel>;
  totalValueUpdated: NormalisedValueSnapshot;
  tradeRequested: TradeHistory;
};

export type TotalGainModel = {
  __typename?: 'TotalGainModel';
  timestamp: Scalars['DateTime'];
  totalGainInBitcoin: Scalars['Decimal'];
};

export type TradeHistory = {
  __typename?: 'TradeHistory';
  buyCoin: Scalars['String'];
  buyingExchange: Scalars['String'];
  buyingMarket: Scalars['String'];
  buyingPrice: Scalars['Decimal'];
  percentageGain: Scalars['Decimal'];
  quantity: Scalars['Decimal'];
  sellCoin: Scalars['String'];
  sellingExchange: Scalars['String'];
  sellingMarket: Scalars['String'];
  sellingPrice: Scalars['Decimal'];
  timestamp: Scalars['DateTime'];
};

export type LatestBalancesQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestBalancesQuery = { __typename?: 'Query', latestBalances: Array<{ __typename?: 'BalanceHistory', timestamp: any, exchange: string, coin: string, quantity: any, quantityInBitcoin: any }> };

export type BalancesUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BalancesUpdatedSubscription = { __typename?: 'Subscription', balancesUpdated: Array<{ __typename?: 'BalanceHistory', timestamp: any, exchange: string, coin: string, quantity: any, quantityInBitcoin: any }> };

export type TotalGainQueryVariables = Exact<{ [key: string]: never; }>;


export type TotalGainQuery = { __typename?: 'Query', totalGain?: { __typename?: 'TotalGainModel', timestamp: any, totalGainInBitcoin: any } | null };

export type TotalGainUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TotalGainUpdatedSubscription = { __typename?: 'Subscription', totalGainUpdated?: { __typename?: 'TotalGainModel', timestamp: any, totalGainInBitcoin: any } | null };

export type TotalValueHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type TotalValueHistoryQuery = { __typename?: 'Query', totalValueHistory: Array<{ __typename?: 'NormalisedValueSnapshot', timestamp: any, totalValueInBitcoin: any }> };

export type TotalValueUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TotalValueUpdatedSubscription = { __typename?: 'Subscription', totalValueUpdated: { __typename?: 'NormalisedValueSnapshot', timestamp: any, totalValueInBitcoin: any } };

export type TradeHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type TradeHistoryQuery = { __typename?: 'Query', tradeHistory: Array<{ __typename?: 'TradeHistory', timestamp: any, sellingExchange: string, sellingMarket: string, sellingPrice: any, sellCoin: string, buyingExchange: string, buyingMarket: string, buyingPrice: any, buyCoin: string, quantity: any, percentageGain: any }> };

export type TradeRequestedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TradeRequestedSubscription = { __typename?: 'Subscription', tradeRequested: { __typename?: 'TradeHistory', timestamp: any, percentageGain: any, sellingExchange: string, sellingMarket: string, sellingPrice: any, sellCoin: string, buyingExchange: string, buyingMarket: string, buyingPrice: any, buyCoin: string, quantity: any } };


export const LatestBalancesDocument = gql`
    query LatestBalances {
  latestBalances {
    timestamp
    exchange
    coin
    quantity
    quantityInBitcoin
  }
}
    `;

export function useLatestBalancesQuery(options?: Omit<Urql.UseQueryArgs<LatestBalancesQueryVariables>, 'query'>) {
  return Urql.useQuery<LatestBalancesQuery>({ query: LatestBalancesDocument, ...options });
};
export const BalancesUpdatedDocument = gql`
    subscription BalancesUpdated {
  balancesUpdated {
    timestamp
    exchange
    coin
    quantity
    quantityInBitcoin
  }
}
    `;

export function useBalancesUpdatedSubscription<TData = BalancesUpdatedSubscription>(options: Omit<Urql.UseSubscriptionArgs<BalancesUpdatedSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<BalancesUpdatedSubscription, TData>) {
  return Urql.useSubscription<BalancesUpdatedSubscription, TData, BalancesUpdatedSubscriptionVariables>({ query: BalancesUpdatedDocument, ...options }, handler);
};
export const TotalGainDocument = gql`
    query TotalGain {
  totalGain {
    timestamp
    totalGainInBitcoin
  }
}
    `;

export function useTotalGainQuery(options?: Omit<Urql.UseQueryArgs<TotalGainQueryVariables>, 'query'>) {
  return Urql.useQuery<TotalGainQuery>({ query: TotalGainDocument, ...options });
};
export const TotalGainUpdatedDocument = gql`
    subscription TotalGainUpdated {
  totalGainUpdated {
    timestamp
    totalGainInBitcoin
  }
}
    `;

export function useTotalGainUpdatedSubscription<TData = TotalGainUpdatedSubscription>(options: Omit<Urql.UseSubscriptionArgs<TotalGainUpdatedSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<TotalGainUpdatedSubscription, TData>) {
  return Urql.useSubscription<TotalGainUpdatedSubscription, TData, TotalGainUpdatedSubscriptionVariables>({ query: TotalGainUpdatedDocument, ...options }, handler);
};
export const TotalValueHistoryDocument = gql`
    query TotalValueHistory {
  totalValueHistory {
    timestamp
    totalValueInBitcoin
  }
}
    `;

export function useTotalValueHistoryQuery(options?: Omit<Urql.UseQueryArgs<TotalValueHistoryQueryVariables>, 'query'>) {
  return Urql.useQuery<TotalValueHistoryQuery>({ query: TotalValueHistoryDocument, ...options });
};
export const TotalValueUpdatedDocument = gql`
    subscription TotalValueUpdated {
  totalValueUpdated {
    timestamp
    totalValueInBitcoin
  }
}
    `;

export function useTotalValueUpdatedSubscription<TData = TotalValueUpdatedSubscription>(options: Omit<Urql.UseSubscriptionArgs<TotalValueUpdatedSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<TotalValueUpdatedSubscription, TData>) {
  return Urql.useSubscription<TotalValueUpdatedSubscription, TData, TotalValueUpdatedSubscriptionVariables>({ query: TotalValueUpdatedDocument, ...options }, handler);
};
export const TradeHistoryDocument = gql`
    query TradeHistory {
  tradeHistory {
    timestamp
    sellingExchange
    sellingMarket
    sellingPrice
    sellCoin
    buyingExchange
    buyingMarket
    buyingPrice
    buyCoin
    quantity
    percentageGain
  }
}
    `;

export function useTradeHistoryQuery(options?: Omit<Urql.UseQueryArgs<TradeHistoryQueryVariables>, 'query'>) {
  return Urql.useQuery<TradeHistoryQuery>({ query: TradeHistoryDocument, ...options });
};
export const TradeRequestedDocument = gql`
    subscription TradeRequested {
  tradeRequested {
    timestamp
    percentageGain
    sellingExchange
    sellingMarket
    sellingPrice
    sellCoin
    buyingExchange
    buyingMarket
    buyingPrice
    buyCoin
    quantity
  }
}
    `;

export function useTradeRequestedSubscription<TData = TradeRequestedSubscription>(options: Omit<Urql.UseSubscriptionArgs<TradeRequestedSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<TradeRequestedSubscription, TData>) {
  return Urql.useSubscription<TradeRequestedSubscription, TData, TradeRequestedSubscriptionVariables>({ query: TradeRequestedDocument, ...options }, handler);
};