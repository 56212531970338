import {
  cacheExchange,
  createClient,
  dedupExchange,
  subscriptionExchange,
} from '@urql/core';
import { createClient as createWSClient } from 'graphql-ws';

const wsClient = createWSClient({
  url: process.env.REACT_APP_GRAPHQL_ENDPOINT_WS!,
});

export const urqlClient = createClient({
  url: process.env.REACT_APP_GRAPHQL_ENDPOINT!,
  exchanges: [
    dedupExchange,
    cacheExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => ({
          unsubscribe: wsClient.subscribe(operation, sink),
        }),
      }),
      enableAllOperations: true,
    }),
  ],
});
