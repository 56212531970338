import { Box, Center, Heading } from '@chakra-ui/react';

type WidgetWrapperProps = {
  heading: string;
  children: React.ReactNode;
};

export const WidgetWrapper = ({
  heading,
  children,
}: WidgetWrapperProps): JSX.Element => {
  return (
    <Box
      border="1px"
      borderColor="gray.200"
      padding="20px"
      borderRadius="md"
      marginY="20px"
      background="white"
    >
      <Heading size="sm" color="gray.800">
        {heading}
      </Heading>
      <Center marginTop="10px">{children}</Center>
    </Box>
  );
};
