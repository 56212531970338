import { useBreakpointValue } from '@chakra-ui/react';
import { formatDistance } from 'date-fns';
import React from 'react';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { NormalisedValueSnapshot } from '../../graphql/graphql';
import { WidgetWrapper } from '../WidgetWrapper';

type TotalValueHistoryWidgetProps = {
  loading: boolean;
  totalValueHistory: NormalisedValueSnapshot[];
};

export const TotalValueHistoryWidget = ({
  loading,
  totalValueHistory,
}: TotalValueHistoryWidgetProps): JSX.Element => {
  const data = React.useMemo(
    () => [...(totalValueHistory ?? [])].reverse(),
    [totalValueHistory],
  );

  if (loading || !totalValueHistory?.length) {
    return <></>;
  }

  return (
    <WidgetWrapper heading="Total value history">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <XAxis
            dataKey="timestamp"
            tickFormatter={(date) => new Date(date).toLocaleDateString()}
            minTickGap={50}
          />
          <YAxis />
          <Line
            dataKey="totalValueInBitcoin"
            dot={false}
            name="Total value (BTC)"
          />
          <Legend />
          <Tooltip
            labelFormatter={(value: string) => new Date(value).toLocaleString()}
            formatter={(value: number) => {
              return value.toFixed(4);
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </WidgetWrapper>
  );
};
