import { Stat, StatHelpText, StatNumber } from '@chakra-ui/react';
import React from 'react';
import { useCombinedTotalGain } from '../../hooks/useCombinedTotalGain';
import { RelativeDate } from '../RelativeDate';
import { WidgetWrapper } from '../WidgetWrapper';

export const TotalGainWidget = (): JSX.Element => {
  const { combinedTotalGain, loading } = useCombinedTotalGain();
  if (loading || !combinedTotalGain) {
    return <></>;
  }

  const latestValueInBitcoin = combinedTotalGain.totalGainInBitcoin.toFixed(5);
  const lastUpdated = new Date(combinedTotalGain.timestamp);

  return (
    <WidgetWrapper heading="Total gain from trades">
      <Stat>
        <StatNumber>{latestValueInBitcoin} BTC</StatNumber>
        <StatHelpText>
          Updated <RelativeDate date={lastUpdated} />
        </StatHelpText>
      </Stat>
    </WidgetWrapper>
  );
};
