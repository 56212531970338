import {
  Box,
  Container,
  Grid,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { useCombinedTotalValueHistory } from '../hooks/useCombinedTotalValueHistory';
import { useGroupedBalanceHistory } from '../hooks/useGroupedBalanceHistory';
import { ExchangeBalanceWidget } from './balanceDistribution/ExchangeBalanceWidget';
import { TotalGainWidget } from './totalGain/TotalGainWidget';
import { TotalValueHistoryWidget } from './totalValue/TotalValueHistoryWidget';
import { TotalValueWidget } from './totalValue/TotalValueWidget';
import { TradeHistoryWidget } from './tradeHistory/TradeHistoryWidget';

export const Content = (): JSX.Element => {
  const {
    combinedTotalValueHistory,
    loading: combinedTotalValueHistoryLoading,
  } = useCombinedTotalValueHistory();

  const { groupedBalances } = useGroupedBalanceHistory();

  const [isWideScreen] = useMediaQuery('(min-width: 1600px)');

  const tradeHistoryWidget = <TradeHistoryWidget key="trade-history-widget" />;
  const totalValueWidget = (
    <TotalValueWidget
      key="total-value-widget"
      loading={combinedTotalValueHistoryLoading}
      latestValue={combinedTotalValueHistory[0]}
    />
  );
  const totalGainWidget = <TotalGainWidget key="total-gain-widget" />;

  const totalValueHistoryWidget = (
    <TotalValueHistoryWidget
      key="total-value-history-widget"
      loading={combinedTotalValueHistoryLoading}
      totalValueHistory={combinedTotalValueHistory}
    />
  );

  const exchangeBalanceWidgets = Object.entries(groupedBalances).map((e) => (
    <ExchangeBalanceWidget key={e[0]} exchange={e[0]} balances={e[1]} />
  ));

  let content: JSX.Element;

  if (isWideScreen) {
    content = (
      <Grid margin={5} gap={5} templateColumns="720px 1fr 400px">
        <GridItem>{tradeHistoryWidget}</GridItem>

        <GridItem>
          {totalValueWidget}
          {totalGainWidget}
          {totalValueHistoryWidget}
        </GridItem>

        <GridItem>{exchangeBalanceWidgets}</GridItem>
      </Grid>
    );
  } else {
    content = (
      <Container maxWidth="1000px">
        {totalValueWidget}
        {totalGainWidget}
        {totalValueHistoryWidget}
        {tradeHistoryWidget}
        {exchangeBalanceWidgets}
      </Container>
    );
  }

  return (
    <Box as="main" flexGrow="1" background="gray.50">
      {content}
    </Box>
  );
};
