import React from 'react';
import {
  useTradeHistoryQuery,
  useTradeRequestedSubscription,
  TradeRequestedSubscription,
} from '../graphql/graphql';

export const useCombinedTradeHistory = (onTrade?: () => void) => {
  const [tradeSnapshot] = useTradeHistoryQuery();
  const [tradeSubscription] = useTradeRequestedSubscription<
    TradeRequestedSubscription[]
  >({}, (messages = [], response) => {
    if (onTrade) {
      setTimeout(onTrade, 0);
    }
    return [response, ...messages];
  });

  const combinedTradeHistory = React.useMemo(
    () => [
      ...(tradeSubscription.data?.map((s) => s.tradeRequested) ?? []),
      ...(tradeSnapshot.data?.tradeHistory ?? []),
    ],
    [tradeSnapshot.data?.tradeHistory, tradeSubscription.data],
  );

  return { loading: tradeSnapshot.fetching, combinedTradeHistory };
};
