import React from 'react';

export const useAudio = (url: string) => {
  const [audio] = React.useState<HTMLAudioElement>(new Audio(url));
  const [playing, setPlaying] = React.useState<boolean>(false);

  const playAudio = () => setPlaying(true);

  React.useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  React.useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return { playAudio };
};
