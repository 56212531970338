import { Td, Tr } from '@chakra-ui/react';
import { TradeHistory } from '../../graphql/graphql';
import { RelativeDate } from '../RelativeDate';

type TradeHistoryTableRowProps = {
  trade: TradeHistory;
};

export const TradeHistoryTableRow = ({
  trade,
}: TradeHistoryTableRowProps): JSX.Element => {
  const date = new Date(trade.timestamp);
  const percentageGain = trade.percentageGain.toFixed(2);
  const quantity = trade.quantity.toFixed(6);

  return (
    <Tr>
      <Td color="gray.600">
        <RelativeDate date={date} />
      </Td>
      <Td>
        {trade.buyCoin}-{trade.sellCoin}
      </Td>
      <Td color="red">{trade.sellingExchange}</Td>
      <Td color="green">{trade.buyingExchange}</Td>
      <Td>
        {quantity} {trade.buyCoin}
      </Td>
      <Td>{percentageGain}%</Td>
    </Tr>
  );
};
