import React from 'react';
import {
  useLatestBalancesQuery,
  useBalancesUpdatedSubscription,
  BalanceHistory,
} from '../graphql/graphql';

export const useGroupedBalanceHistory = (): {
  loading: boolean;
  groupedBalances: Record<string, BalanceHistory[]>;
} => {
  const [balanceSnapshot] = useLatestBalancesQuery();
  const [balanceSubscription] = useBalancesUpdatedSubscription();

  const groupedBalances = React.useMemo(() => {
    const latestBalances =
      balanceSubscription.data?.balancesUpdated ??
      balanceSnapshot.data?.latestBalances ??
      [];

    return latestBalances.reduce(
      (prev: Record<string, BalanceHistory[]>, curr: BalanceHistory) => {
        if (!prev.hasOwnProperty(curr.exchange)) {
          prev[curr.exchange] = [];
        }

        prev[curr.exchange].push(curr);

        return prev;
      },
      {},
    );
  }, [
    balanceSubscription.data?.balancesUpdated,
    balanceSnapshot.data?.latestBalances,
  ]);

  return { loading: balanceSnapshot.fetching, groupedBalances };
};
