import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link, Text, VStack } from '@chakra-ui/react';

export const Footer = (): JSX.Element => {
  return (
    <VStack
      as="footer"
      backgroundColor="gray.100"
      color="gray.600"
      paddingTop="20px"
      paddingBottom="20px"
    >
      <Text>Jordan Sim-Smith</Text>

      <br />

      <Link href="https://github.com/jordansimsmith/archie" isExternal>
        See this code on GitHub <ExternalLinkIcon />
      </Link>
    </VStack>
  );
};
