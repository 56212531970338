import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { useAudio } from '../../hooks/useAudio';
import { useCombinedTradeHistory } from '../../hooks/useCombinedTradeHistory';
import { WidgetWrapper } from '../WidgetWrapper';
import { TradeHistoryTableRow } from './TradeHistoryTableRow';

export const TradeHistoryWidget = (): JSX.Element => {
  const { playAudio } = useAudio('/trade.wav');
  const { combinedTradeHistory, loading } = useCombinedTradeHistory(playAudio);

  if (loading) {
    return <></>;
  }

  return (
    <WidgetWrapper heading="Trade history">
      <TableContainer maxHeight="1000px" overflowY="auto">
        <Table size="sm" variant="simple" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Time</Th>
              <Th>Pair</Th>
              <Th>Selling</Th>
              <Th>Buying</Th>
              <Th>Quantity</Th>
              <Th>Gain</Th>
            </Tr>
          </Thead>
          <Tbody>
            {combinedTradeHistory.map((t, i) => (
              <TradeHistoryTableRow key={t.timestamp + i} trade={t} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </WidgetWrapper>
  );
};
