import { formatDistance } from 'date-fns';
import React from 'react';

type RelativeDateProps = {
  date: Date;
};

export const RelativeDate = ({ date }: RelativeDateProps): JSX.Element => {
  const [currentDate, setCurrentDate] = React.useState(() => new Date());

  React.useEffect(() => {
    const interval = setInterval(() => setCurrentDate(new Date()), 60_000);

    return () => clearInterval(interval);
  }, []);

  const relativeDate = formatDistance(date, currentDate, { addSuffix: true });
  return <>{relativeDate}</>;
};
