import { Stat, StatHelpText, StatNumber } from '@chakra-ui/react';
import React from 'react';
import { NormalisedValueSnapshot } from '../../graphql/graphql';
import { RelativeDate } from '../RelativeDate';
import { WidgetWrapper } from '../WidgetWrapper';

type TotalValueWidgetProps = {
  loading: boolean;
  latestValue?: NormalisedValueSnapshot;
};

export const TotalValueWidget = ({
  loading,
  latestValue,
}: TotalValueWidgetProps): JSX.Element => {
  if (loading || !latestValue) {
    return <></>;
  }

  const latestValueInBitcoin = latestValue.totalValueInBitcoin.toFixed(5);
  const lastUpdated = new Date(latestValue.timestamp);

  return (
    <WidgetWrapper heading="Total value">
      <Stat>
        <StatNumber>{latestValueInBitcoin} BTC</StatNumber>
        <StatHelpText>
          Updated <RelativeDate date={lastUpdated} />
        </StatHelpText>
      </Stat>
    </WidgetWrapper>
  );
};
