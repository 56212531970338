import {
  TotalGainUpdatedSubscription,
  useTotalGainQuery,
  useTotalGainUpdatedSubscription,
} from '../graphql/graphql';

export const useCombinedTotalGain = () => {
  const [totalGain] = useTotalGainQuery();
  const [totalValueSubscription] = useTotalGainUpdatedSubscription<
    TotalGainUpdatedSubscription[]
  >({}, (messages = [], response) => [response]);

  const combinedTotalGain =
    totalValueSubscription.data?.[0]?.totalGainUpdated ??
    totalGain.data?.totalGain;

  return {
    loading: totalGain.fetching,
    combinedTotalGain,
  };
};
